
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import { Field } from "vee-validate";
import { defineComponent } from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
moment.locale("id");

import { Modal } from "bootstrap";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      tableData: [],
      listSekolah: [],
      // startDate: "",
      // endDate: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      sortkonfirmasi: true,
      oldStatus: "",
      listConfirmation: [
        {
          label: "Menunggu Konfirmasi",
          value: "WAIT",
        },
        {
          label: "Ditolak",
          value: "REJECT",
        },
        {
          label: "Diterima",
          value: "ACCEPT",
        },
      ],
      listType: [],
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      items: {
        errornote: "",
        errorconfirmation: "",
      },
      name: "",
      schoolId: "",
      schoolName: "",
      status: "",
      confirmation: "",
      baseUrl: "crmv2/main_invoice/invoice/office/invoice",
      fotoforensik: "",
      roleuser: "",
      filterConfir: "&sort=updateTime&dir=-1",
      itemId: "",
      formDetail: {
        createTime: "",
        creatorId: "",
        updateTime: "",
        editorId: "",
        isDelete: false,
        deleteBy: null,
        deleteTime: null,
        schoolId: "",
        paymentId: "",
        schoolName: "",
        note: "",
        title: "",
        item: "",
        detail: [],
        invoiceDate: "",
        expiredDate: "",
        paymentDate: "",
        sourceNumber: "",
        number: "",
        refNumber: "",
        amount: 0,
        paidAmount: 0,
        bankNumbSender: "",
        bankNameSender: "",
        bankNumbTo: "",
        bankNameTo: "",
        pic: "",
        executor: "",
        financeExecutor: null,
        imageReceipt: [],
        status: "ACCEPT",
        _id: "",
      },
      statusFromNotif: "false",
      valueIDLocalStorage: "",
      showFilter: false,
      timer: 0,
    };
  },
  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    this.schoolId = JSON.parse(localStorage.getItem("sekolahId")!);
    this.schoolName = JSON.parse(localStorage.getItem("schoolName")!);
    this.roleuser = JSON.parse(localStorage.getItem("user_account")!).role;
    // if (this.roleuser == "ADMIN_FINANCE") {
    if (this.roleuser == "ADMIN_SCHOOL") {
      this.schoolId = JSON.parse(
        localStorage.getItem("user_account")!
      ).schoolId;
    }
    if (
      [
        "ADMIN_FINANCE",
        "SUPER_ADMIN",
        "SALES_AREA",
        "SALES_LEADER",
        "CS_LEADER",
        "CS_MEMBER",
        "IMPLEMENTATOR_LEADER",
        "IMPLEMENTATOR_MEMBER",
      ].includes(this.roleuser)
    ) {
      this.status = "WAIT";
      this.startDate = "";
      this.endDate = "";
    }
    this.statusFromNotif = localStorage.getItem(
      "statusKonfirmasiFinancefromNotif"
    )!;
    if (this.statusFromNotif)
      if (this.statusFromNotif == "true") {
        this.startDate = localStorage.getItem("tglKonfirmasiFinancefromNotif")!;
        this.valueIDLocalStorage = localStorage.getItem(
          "idKonfirmasiFinancefromNotif"
        )!;
        localStorage.removeItem("statusKonfirmasiFinancefromNotif");
        localStorage.removeItem("idKonfirmasiFinancefromNotif");
        localStorage.removeItem("tglKonfirmasiFinancefromNotif");
      }
    console.log("before mount");
    this.getData(this.paging.size, this.paging.page);
    this.getListSekolah();
  },
  mounted() {
    if (this.statusFromNotif == "true")
      if (this.valueIDLocalStorage)
        this.getDetailModal(this.valueIDLocalStorage);
  },
  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound)
        highBound = this.paging.totalElements;
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    dateTime2(value) {
      return moment(value).format("DD MMMM YYYY");
    },
    getData(size, page) {
      if (this.status !== "WAIT" && (!this.startDate || !this.endDate)) {
        return;
      }
      let konfirmasi = "";
      let setatus = "";
      let sekolah = "";
      this.isLoading = true;
      if (this.status) setatus = this.status;
      if (this.schoolId) sekolah = this.schoolId;
      if (this.confirmation)
        konfirmasi = "confirmation=" + this.confirmation + "&";

      let dateawal = this.startDate
        ? moment(this.startDate).format("YYYY-MM-DD")
        : "";
      let dateakhir = this.endDate
        ? moment(this.endDate).format("YYYY-MM-DD")
        : "";
      if (this.status == "WAIT") {
        if (this.startDate && this.endDate) {
          dateakhir = moment(this.endDate).format("YYYY-MM-DD");
          dateawal = moment(this.startDate).format("YYYY-MM-DD");
        }
      } else {
        if (!this.startDate || !this.endDate) {
          dateakhir = "";
          dateawal = "";
        }
      }
      let initurl = "crmv2/main_invoice/invoice/invoice";
      if (this.roleuser === "SUPER_ADMIN")
        initurl = "crmv2/main_invoice/invoice/office/invoice";
      this.paging.page = page;
      ApiService.getWithoutSlug(
        `${initurl}/confirm/all?schoolId=${sekolah}&dateFrom=${dateawal}&dateUntil=${dateakhir}&page=${page}&size=${size}&status=${setatus}&sort=updateTime&dir=-1`
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.listType = data.content;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.tableData = [];
          this.isLoading = false;
        });
    },

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
        }
      );
    },
    pilihStatus(event) {
      console.log("pilih status");

      this.status = "";
      if (event) this.status = event;
      if (this.status !== "WAIT" && (!this.startDate || !this.endDate)) {
        if (!this.startDate) this.startDate = moment().format("YYYY-MM-DD");
        if (!this.endDate) this.endDate = moment().format("YYYY-MM-DD");
      } else if (this.status === "WAIT") {
        this.startDate = "";
        this.endDate = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },
    pilihSekolah(event) {
      console.log("pilih sekolah");
      var namaSekolah = "";
      this.schoolId = "";
      if (event) {
        this.schoolId = event;
        let school: any = this.listSekolah.find(
          (item: any) => item._id == this.schoolId
        );
        namaSekolah = school.name;
        this.schoolName = school.name;
      }
      localStorage.setItem("schoolName", JSON.stringify(namaSekolah));
      localStorage.setItem("sekolahId", JSON.stringify(event));
      this.getData(this.paging.size, this.paging.page);
    },
    getImage(item) {
      ApiService.getWithoutSlug(
        `crmv2/main_image/image/get/base64/thumb/${item}`
      ).then((resp) => {
        this.fotoforensik = "data:image/jpeg;base64, " + resp.data;
      });
    },
    cekData(e) {
      this.status = e.target.value;
    },
    getDetailModal(valueID) {
      this.itemId = valueID;
      this.isLoading = true;
      const toast = useToast();
      var stt = "";
      var initurl = "crmv2/main_invoice/invoice/invoice";
      if (this.roleuser === "SUPER_ADMIN")
        initurl = "crmv2/main_invoice/invoice/office/invoice";

      ApiService.getWithoutSlug(`${initurl}/confirm/${valueID}`)
        .then((res) => {
          stt = res.data.status == "REJECT" ? "reject" : "accept";
          this.formDetail = res.data;
          this.oldStatus = res.data.status;
          this.status = stt;
          this.getImage(res.data.imageReceipt[0]);
          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
      const modal = new Modal(
        document.getElementById("kt_modal_confirm_legal")
      );
      modal.show();
    },
    getId(index, item) {
      this.itemId = item._id;
      this.isLoading = true;
      const toast = useToast();
      var stt = "";
      var initurl = "crmv2/main_invoice/invoice/invoice";
      if (this.roleuser === "SUPER_ADMIN")
        initurl = "crmv2/main_invoice/invoice/office/invoice";

      ApiService.getWithoutSlug(`${initurl}/confirm/${item._id}`)
        .then((res) => {
          stt = res.data.status == "REJECT" ? "reject" : "accept";
          this.formDetail = res.data;
          this.oldStatus = res.data.status;
          // this.status = stt;
          this.getImage(res.data.imageReceipt[0]);
          this.isLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    nextPage() {
      console.log("next");
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      console.log("prev");
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize() {
      console.log("change page");
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    submitAdd() {
      const toast = useToast();
      var data = {
        note: this.formDetail.note,
      };
      var a = this.formDetail.status == "accept" ? "ACCEPT" : "REJECT";
      var initurl = "crmv2/main_invoice/invoice/invoice";
      if (this.roleuser === "SUPER_ADMIN")
        initurl = "crmv2/main_invoice/invoice/office/invoice";
      ApiService.putWithData(
        `${initurl}/confirm/${this.formDetail._id}?status=${a}`,
        data
      )
        .then((res) => {
          toast.success("Berhasil Update Detail Konfirmasi Finance");
          this.isLoading = false;
          var modalEl = document.getElementById("kt_modal_confirm_legal");
          const myModal = Modal.getInstance(modalEl);
          myModal.hide();
          setTimeout(
            () => this.$router.push({ path: "/superadmin/konfirmasi-invoice" }),
            1000
          );
          location.reload();
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push("/sign-in");
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
          this.isLoading = false;
        });
    },
    eventDelaySearch(e) {
      clearTimeout(this.timer); //this timer 0 - set 0 atau clear jika ada input lagi
      this.timer = setTimeout(() => {
        const page = this.paging.page;
      }, 1000);
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
});
